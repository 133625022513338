import LayoutA3 from "../../components/layout-a3"
import React from "react"
import EnrollInfoDetail from '../../components/enroll-info-detail'

export default class A3EnrollInfoDetail extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    return(
      <LayoutA3 location={this.props.location} >
        <EnrollInfoDetail  color={color}/>
      </LayoutA3>
    )
  }
}